import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Img from 'gatsby-image'
import ContentPadding from '../components/ContentPadding'
import { HTMLContent } from '../components/Content'
import ColoredHeader from '../components/ColoredHeader'
import { TeamSection } from '../components/TeamSection'
import Helmet from 'react-helmet'

const TeamPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet title="Voices Across Time | About" />
      <Img className="header-img" fluid={post.frontmatter.header_image.childImageSharp.fluid} alt="" />
      <ContentPadding>
        <section className="section">
          <div className="content has-text-justified">
            <HTMLContent content={post.html} />
          </div>
        </section>
      </ContentPadding>
      {/* eslint-disable-next-line */}
      <a className="anchor" name="team"></a>
      <ColoredHeader>{post.frontmatter.members_title}</ColoredHeader>
      <ContentPadding>
        <TeamSection members={post.frontmatter.members} image_position="left" />
      </ContentPadding>
    </Layout>
  )
}

export default TeamPage

export const TeamPageQuery = graphql`
  query TeamPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        header_image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 75, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        members_title
        members {
          name
          bio
          image {
            childImageSharp {
              fixed(width: 200, height: 200, quality: 90, toFormat: JPG) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      html
    }
  }
`
